<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="form"> -->
  <UiModalContainer
    :modal-show="modal"
    :modal-width="50"
    footer-classes="flex justify-end gap-3"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Add {{ title }} Pattern</span>
    </template>
    <template v-slot>
      <div>
        <span v-if="examplePattern" class="text-xs">
          Suggested pattern example: (
          <span class="text-primary-green">{{ examplePattern }}</span>
          )
        </span>
        <div class="flex lg:gap-3 flex-col lg:flex-row mb-5">
          <UISplitButton
            v-model="registrationPattern.year"
            reduce="value"
            class="flex-1"
            :options="yearsPattern"
            @updatedValue="appendValueToPattern"
          />
          <UISplitButton
            v-model="registrationPattern.month"
            reduce="value"
            class="flex-1"
            :options="monthsPattern"
            @updatedValue="appendValueToPattern"
          />
        </div>
        <div class="flex lg:gap-3 flex-col lg:flex-row">
          <UISplitButton
            v-model="registrationPattern.autoIncrementNumber"
            reduce="value"
            class="flex-1"
            :is-input="true"
            input-placeholder="Auto Increment Starts From"
            :options="autoIncrementPattern"
            @updatedValue="appendValueToPattern($event, true)"
          />
        </div>
        <div class="flex gap-5 items-center my-10">
          <div class="text-base text-text-color">{{ title }} Pattern</div>
          <UiInput
            v-model="finalRegistrationPattern"
            type="text"
            rules="required"
            :no-error="true"
            placeholder="C_P"
            class="flex-1"
            @blurEvent="updateCursorIndex"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="dashboard"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(savePattern(invalid))"
      >
        <span>Save</span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapState, mapActions } from 'vuex'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UISplitButton from '@src/components/UiElements/UISplitButton.vue'
export default {
  name: 'CusomPattern',
  components: {
    UiInput,
    UiModalContainer,
    // ValidationObserver,
    UISplitButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modal: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    delimeter: { type: String, default: '' },
    surrondingDelimeter: {
      type: Array,
      default: () => ['{', '}'],
      validator(arr) {
        return arr.length === 2
      },
    },
    examplePattern: { type: String, default: '' },
    title: { type: String, default: 'Registration' },
  },
  emits: ['update:modelValue', 'toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      isActive: false,
      isOpen: false,
      cursorPosition: 0,
      yearsPattern: {
        title: 'Year',
        reduce: 'value',
        subOptions: [
          {
            title: 'YYYY',
            value: 'YYYY',
          },
          {
            title: 'YY',
            value: 'YY',
          },
        ],
      },
      monthsPattern: {
        title: 'Month',
        reduce: 'value',
        subOptions: [
          {
            title: 'MM',
            value: 'MM',
          },
          {
            title: 'M',
            value: 'M',
          },
          {
            title: 'Month',
            value: 'Month',
          },
          {
            title: 'Mon',
            value: 'Mon',
          },
        ],
      },
      autoIncrementPattern: {
        title: 'Auto Increment Starts From ',
      },
      registrationPattern: {
        year: '',
        month: '',
        autoIncrementNumber: '',
      },
      finalRegistrationPattern: '',
    }
  },
  mounted() {
    if (this.modelValue) {
      this.finalRegistrationPattern = this.modelValue
      this.cursorPosition = this.modelValue.length
    }
  },
  methods: {
    updateCursorIndex(e) {
      this.cursorPosition = e.target.selectionStart
    },

    appendValueToPattern(value, addAI = false) {
      let stringStart = '',
        stringEnd = ''
      if (this.finalRegistrationPattern) {
        stringStart = this.finalRegistrationPattern.substring(0, this.cursorPosition)
        stringEnd = this.finalRegistrationPattern.substring(this.cursorPosition)
      }

      value = addAI ? `{AI:${value}}` : `{${value}}`

      this.finalRegistrationPattern = `${stringStart}${value}${stringEnd}`
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('toggle')
          break
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },

    savePattern() {
      this.$emit('update:modelValue', this.finalRegistrationPattern)
      this.$emit('toggle', this.finalRegistrationPattern)
    },
  },
}
</script>

<template>
  <div>
    <FeeTemplateFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <div class="main-content">
      <div v-if="isLoading" class="mt-28">
        <Loader :content="true" />
      </div>
      <div v-else-if="!isLoading && isEmpty(feeTemplates)">
        <NoRecordFound />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH
              v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
              v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
              :key="head.id"
            >
              {{ head.dataTableName }}
            </TH>
            <TH
              v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
              v-permission="campusLevelPermisions"
            >
              <span v-i18n="dashboard">Class</span>
            </TH>
            <TH
              v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
              v-permission="classAndCampusPermission"
            >
              <span v-i18n="dashboard">Section</span>
            </TH>
            <TH v-show="showColumnInDataTable(filtersData, 'Actions')" v-i18n="dashboard">
              <span>Actions</span>
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(fee, index) in feeTemplates" :key="index">
            <TD v-show="showColumnInDataTable(filtersData, 'Amount', ['Amount'])">
              {{ fee.fee_amount }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Currency')">
              <span class="uppercase">
                {{ fee.currency }}
              </span>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Fee Type', ['Fee Type'])">
              {{ fee.fee_type.title }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Auto Generate On Date of Month')">
              {{ fee.generate_on_day_of_month || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Generate Days Before End of Month')">
              {{ fee.generate_days_before_end_of_month || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Due Date of Fee Payment')">
              {{ fee.due_date_on_day_of_month }}
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
              v-permission="campusLevelPermisions"
            >
              <BadgeDisplay :options="[fee.section.class]" label="title" class="pl-8" />
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
              v-permission="classAndCampusPermission"
            >
              <BadgeDisplay :options="[fee.section]" label="title" class="pl-8" />
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
              <TableAction
                :idx="index"
                :current-user="fee"
                :action-list="actionList"
                @action="plannerAction"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>

      <Pagination
        v-if="showPagination"
        v-show="!contentLoading"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(feeTemplatesCounts, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <UIConfirmationModal
        v-if="deleteModelShow"
        heading="Monthly Fee Generator"
        message="Are you sure you want to delete "
        button-text="Delete"
        :modal="deleteModelShow"
        name="Monthly"
        remaining-message="Fee Planner ?"
        @cancel="toogleDeleteModel"
        @confirm="deleteFeePlannerById"
      />

      <SalaryTitle
        v-if="feeTitleModal"
        v-model="currentFeePlanner.feeTitle"
        :modal="feeTitleModal"
        title="Fee Title"
        example-pattern="{YYYY}{MM}{AI:222}"
        @toggle="toggleFeeTitleModal"
      />
      <MonthlyFeeGenerator
        v-if="showMonthlyFeeGeneratorModal"
        :show-modal="showMonthlyFeeGeneratorModal"
        :edit="currentFeePlanner"
        @toggle="toggleMonthlyFeeGeneratorModal"
      />
    </div>
  </div>
</template>

<script>
import FeeTemplateFilter from '@views/finance/fees/monthly-fee-generator/MonthlyFeeGeneratorFilters.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import GeneralUtil from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import scrollMixin from '@src/mixins/scroll-mixin'
import SalaryTitle from '@src/router/views/settings/system-settings/registration-pattern/RegistrationPatternModal.vue'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil.js'
import MonthlyFeeGenerator from '@src/router/views/finance/fees/modals/MonthlyFeeModal.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import FEE_DATA from '@/src/router/views/finance/fees/received/received.json'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    FeeTemplateFilter,
    Loader,
    TableAction,
    Pagination,
    SalaryTitle,
    MonthlyFeeGenerator,
    NoRecordFound,
    BadgeDisplay,
    UIConfirmationModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralUtil, fileMixins, scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      SCOPE_LEVELS,
      skipForFilterColumns: FEE_DATA.feeGeneratorSkipForFilterColumns,
      defaultSelectedColumns: FEE_DATA.feeGeneratorDefaultSelectedColumns,
      tableHeaders: FEE_DATA.feeGeneratorTableHeaders,
      dashboard: 'dashboard',
      campusLevelPermisions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      classAndCampusPermission: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
      },
      fees: 'fees',
      feeTitleModal: false,
      addFeeData: {
        fee_planner_id: '',
        due_date: '',
        status: 'due',
        fee_amount: '',
        fee_type_id: '',
        description: '',
      },
      deleteModelShow: false,

      actionList: [
        { name: 'Edit Monthly Fee Generator' },
        { name: 'Delete' },
        { name: 'Add Fee Title' },
      ],
      filtersData: {},
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      showPagination: false,
      contentLoading: false,
      currentFeePlanner: null,
      showMonthlyFeeGeneratorModal: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      feeTemplates: (state) => state.fee.feeTemplates?.records,
      feeTemplatesCounts: (state) => state.fee.feeTemplates?.meta.total_records,
      currentClassScope: (state) => state.layout.currentClassScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentCampusScope: (state) => state.layout.currentCampusScope,
    }),
    filtersPayload() {
      return {
        $where: {
          ...this.filtersData?.$where,
        },
      }
    },
  },
  watch: {
    showModal: {
      deep: true,
      handler(value) {
        if (value) this.toggleMonthlyFeeGeneratorModal()
      },
    },
    currentCampusScope: {
      handler(value) {
        if (value) this.filterRecord()
      },
    },
    currentSectionScope: {
      handler(value) {
        this.filterRecord()
      },
    },
  },
  /**
   * Mounted hook
   * @description Calling filterRecord method
   */
  mounted() {
    this.righBarData()
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    toggleFeeTitleModal() {
      this.feeTitleModal = !this.feeTitleModal
    },
    /**
     * Filter Record
     * @param {number} range
     * @returns {void}
     * @description Method description
     * -  Filter the record on basis of range
     * -  Setting right bar data
     */
    filterRecord(range) {
      if (!this.currentCampusScope) return

      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
        ...this.filtersPayload,
      }
      this.getFeeTemplate(payload)
        .then((response) => {
          this.showPagination = this.feeTemplatesCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          this.righBarData(this.feeTemplatesCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    righBarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Monthly Fee Generator',
          buttons: [
            {
              title: 'Add Monthly Fee Generator',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_PLANNER',
                value: count,
                tooltipDescription: 'Total no count. of auto fee created',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    toggleMonthlyFeeGeneratorModal(payload) {
      this.showMonthlyFeeGeneratorModal = !this.showMonthlyFeeGeneratorModal
      if (!this.showMonthlyFeeGeneratorModal) {
        this.currentFeePlanner = null
        this.$store.dispatch('layout/setShowModal', false)
      }
      if (payload) this.filterRecord()
    },
    /**
     * Planner Action
     * @param {String} action
     * @param {object} currentPlanner
     * @param {number} idx - Index of current planner
     * @returns {void}
     * @description Setting the action planner on the basis of input id
     */
    plannerAction(action, currentPlanner, idx) {
      this.currentFeePlanner = currentPlanner
      switch (action) {
        case 'Edit Monthly Fee Generator':
          this.toggleMonthlyFeeGeneratorModal()
          break
        case 'Delete':
          this.deleteModelShow = true
          break
        case 'Add Fee Title':
          this.toggleFeeTitleModal()
          break
      }
    },
    async deleteType(id, title) {
      await this.deleteFeePlanner({ id: id, title: title })
      this.filterRecord()
      this.toogleDeleteModel()
      this.currentFeePlanner = null
    },
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },
    deleteFeePlannerById() {
      this.deleteType(this.currentFeePlanner.id, this.currentFeePlanner.fee_type.title)
    },
    /**
     * Reset Form
     * @description Resetting the data of form by assigning empty values
     */
    ...mapActions('layout', ['setRightbarContent', 'setShowModal', 'getUserSpecificSectionsList']),
    ...mapActions('fee', ['addStudentFees', 'getFeeTemplate', 'deleteFeePlanner']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

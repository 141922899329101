<template>
  <ValidationObserver @submit="addTemplate">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="!edit" v-i18n="fees">Add Monthly Fee Generator</span>
        <span v-else v-i18n="fees">Edit Monthly Fee Generator</span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="feeTemplate.fee_type_id"
              title="FEE_TYPES"
              :options="feeTypes"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
              :search-through-api="true"
              @search="fetchFeeTypes"
            />
            <UiInput
              v-model.number="feeTemplate.fee_amount"
              type="text"
              name="Amount"
              title="Amount"
              label="Amount"
              placeholder="Amount"
              class="flex-1"
              rules="required|numeric"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiInput
              v-model.number="feeTemplate.generate_on_day_of_month"
              type="text"
              name="Generate_on_day_of_month"
              title="Generate_on_day_of_month"
              label="Generate_on_day_of_month"
              placeholder="PLACEHOLDER_ADD_TEMPLATE"
              :disabled="!!feeTemplate.generate_days_before_end_of_month"
              class="flex-1"
              :rules="
                feeTemplate.generate_days_before_end_of_month ? 'numeric' : 'required|numeric'
              "
            />
            <UiInput
              v-model.number="feeTemplate.due_date_on_day_of_month"
              type="text"
              title="Due Date of Fee Payment"
              label="Due_date_of_day_of_month"
              placeholder="PLACEHOLDER_ADD_TEMPLATE"
              class="flex-1"
              rules="required|numeric"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiInput
              v-model.number="feeTemplate.generate_days_before_end_of_month"
              type="text"
              name="Generate_before_end_of_month"
              title="Generate_before_end_of_month"
              label="Generate_before_end_of_month"
              placeholder="PLACEHOLDER_ADD_TEMPLATE"
              class="flex-1"
              :disabled="!!feeTemplate.generate_on_day_of_month"
              :rules="feeTemplate.generate_on_day_of_month ? 'numeric' : 'required|numeric'"
            />
            <UiSingleSelect
              v-model="feeTemplate.currency"
              :rules="'required'"
              :options="allowedCurrencies"
              reduce="id"
              title="CURRENCY_FOR_FEE"
              class="flex-1"
            />
          </InputFieldWrapper>
          <InputFieldWrapper v-if="!currentSectionScope">
            <UiSingleSelect
              v-model="feeTemplate.class_id"
              title="CLSS"
              :options="classesList"
              label="title"
              class="flex-1"
              reduce="id"
              :disabled="!!currentClassScope"
              rules="required"
              @change="setSection(feeTemplate.class_id)"
            />
            <UiSingleSelect
              v-model="feeTemplate.section_id"
              title="Section"
              :options="sectionsList"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
            />
          </InputFieldWrapper>
          <div class="flex justify-end mt-3">
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button ml-auto px-2"
              @click.prevent="addNewCustomFee"
            >
              <span>Add Custom Fee</span>
            </button>
          </div>
          <div v-for="(fee, index) in customFeeList" :key="index">
            <InputFieldWrapper class="sm:items-center">
              <UiSingleSelect
                v-model="fee.student_id"
                title="Student"
                label="full_name"
                :name="`Student${index}`"
                :options="studentsList"
                reduce="id"
                class="flex-1"
                input-color="bg-white"
                :search-through-api="true"
                @search="getStudentList"
              />
              <UiInput
                v-model="fee.fee_amount"
                type="text"
                title="Fee Amount"
                label="Fee Amount"
                :is-not-translated="true"
                :name="`feeAmount + ${index}`"
                placeholder="Fee Amount"
                rules="numeric"
                class="flex-1"
              />
              <UiInput
                v-model="fee.reason"
                type="text"
                title="Reason"
                :is-not-translated="true"
                :name="`reason + ${index}`"
                label="Reason"
                placeholder="Reason"
                class="flex-1"
              />
              <span class="flex justify-end" @click="removeFee(index)">
                <icon
                  class="icon cursor-pointer"
                  color="text-grey"
                  icon="crossCircle"
                  height="20"
                  width="20"
                />
              </span>
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              <span v-if="!edit">Add</span>
              <span v-else>Update</span>
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import icon from '@src/components/icons/icon.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    Loader,
    UiModalContainer,
    UiInput,
    ValidationObserver,
    UiSingleSelect,
    icon,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    showModal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      fees: 'fees',
      isCustomFee: false,
      feeTemplate: {
        planner_level: 'class',
        section_id: '',
        fee_type_id: '',
        fee_amount: '',
        due_date_on_day_of_month: '',
        generate_on_day_of_month: '',
        generate_days_before_end_of_month: '',
        is_active: true,
        currency: '',
        fee_planner_customizations: [],
        class_id: '',
      },
      isLoading: false,
      customFeeList: [],
      studentsList: [],
      invalidCustomFeeData: false,
      sectionsList: [],
      classesList: [],
    }
  },
  computed: {
    ...mapState({
      feeTypes: (state) => state.fee.feeTypes?.records,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      defaultCurrencyForFee: (state) =>
        state.settings?.siteSetting?.default_currency?.default_currency,
    }),
    ...mapGetters('layout', ['allowedCurrencies']),
  },
  mounted() {
    this.init()
    if (this.edit) this.setFeeTemplateData()
    if (this.currentCampusScope) this.getCampusClasses()
  },
  methods: {
    init() {
      this.feeTemplate.currency = this.defaultCurrencyForFee
      this.feeTemplate.section_id = this.currentSectionScope?.id
      this.fetchFeeTypes()
      this.getStudentList()
    },
    fetchFeeTypes(query = '') {
      let queryPayload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) } }
      this.getFeeTypes(queryPayload)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    getCampusClasses() {
      let payload = {
        campus_id: this.currentCampusScope?.id,
      }
      this.getClassesList(payload).then((res) => {
        this.classesList = res.records
        if (this.currentClassScope) {
          this.feeTemplate.class_id = this.currentClassScope?.id
          this.setSection(this.feeTemplate.class_id)
        }
      })
    },
    setSection(id) {
      this.classesList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsList = classItem.sections
      })
    },
    addNewCustomFee() {
      if (this.customFeeList.length >= this.studentsList.length) {
        this.generateToast('error', 'No Student Available')
        return
      }
      let newCustomFee = {
        student_id: '',
        fee_amount: 0,
        reason: '',
      }
      this.customFeeList.push(newCustomFee)
    },
    removeFee(position) {
      this.customFeeList.splice(position, 1)
    },

    generateToast(type = 'error', message = '') {
      this.$store.commit('toast/NEW', { message: message, type: type })
    },
    setCustomFeeData() {
      this.invalidCustomFeeData = false
      this.invalidCustomFeeData = this.customFeeList.some((fee) => {
        return !fee.student_id
      })
      if (this.invalidCustomFeeData) {
        this.generateToast('error', 'Please select all students first')
        return
      }
      this.feeTemplate.fee_planner_customizations = this.customFeeList
    },

    addTemplate() {
      this.setCustomFeeData()
      if (this.invalidCustomFeeData) return

      this.isLoading = true
      const data = {
        templateData: this.feeTemplate,
      }
      if (this.edit) data.id = this.edit?.id
      this.addFeeTemplate(data)
        .then((response) => {
          if (this.edit)
            this.$store.commit(
              'toast/NEW',
              {
                type: 'success',
                message: `${response?.data?.fee_type?.title} ${this.$t(
                  `toast.is updated successfully`,
                )}`,
              },
              { root: true },
            )
          else
            this.$store.commit(
              'toast/NEW',
              {
                type: 'success',
                message: `${response?.data?.fee_type?.title} ${this.$t(
                  `toast.successfully created`,
                )}`,
              },
              { root: true },
            )

          this.handleClick(false)
          this.resetForm()
          this.$emit('toggle', 'fetch')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getStudentList(query = '') {
      let payload = {
        first_name: query,
      }

      this.getStudentsWithFilters(payload)
        .then((res) => {
          this.studentsList = res?.records
        })
        .catch((error) => {
          return error
        })
    },
    setFeeTemplateData() {
      this.feeTemplate.fee_type_id = this.edit?.fee_type?.id
      this.feeTemplate.fee_amount = this.edit?.fee_amount
      this.feeTemplate.due_date_on_day_of_month = this.edit?.due_date_on_day_of_month
      this.feeTemplate.generate_on_day_of_month = this.edit?.generate_on_day_of_month
      this.feeTemplate.generate_days_before_end_of_month =
        this.edit?.generate_days_before_end_of_month
      this.feeTemplate.currency = this.edit?.currency
      this.customFeeList = this.edit?.fee_planner_customizations || []
      this.getClassesList({
        campus_id: this.currentCampusScope?.id,
      }).then((res) => {
        this.classesList = res.records
        this.feeTemplate.class_id = this.edit?.section?.class?.id || ''
        this.setSection(this.feeTemplate.class_id)
        this.feeTemplate.section_id = this.edit?.section?.id || ''
      })
    },

    resetForm() {
      this.feeTemplate.planner_level = 'class'
      this.feeTemplate.fee_amount = ''
      this.feeTemplate.generate_on_day_of_month = ''
      this.feeTemplate.due_date_on_day_of_month = ''
      this.feeTemplate.fee_type_id = ''
      this.feeTemplate.currency = this.defaultCurrencyForFee
    },
    ...mapActions('fee', ['addFeeTemplate', 'getFeeTypes']),
    ...mapActions('student', ['getStudentsWithFilters']),
    ...mapActions('classes', ['getClassesList']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<template>
  <UiOnClickOutside :do="closeDropdown">
    <div class="relative">
      <button
        :class="[{ borderBottomRadius: isDropdownOpen === true }]"
        class="splitBtn focus:outline-none focus:shadow-md primary-button-right w-full gap-5 mt-5 text-sm md:text-base text-white font-medium font-roboto h-11 md:h-14 flex justify-between items-center px-5 md:px-7"
        @click.prevent="showDropDown"
      >
        <div class="flex items-center">
          <div v-if="options.icon" class="ltr:pr-3 rtl:pl-3">
            <icon :icon="options.icon" width="18" height="18" />
          </div>
          <span>{{ options.title }}</span>
        </div>
        <span>
          <icon
            class="icon"
            :icon="isDropdownOpen ? 'chevronUp' : 'chevronBottom'"
            height="9"
            width="15"
          />
        </span>
      </button>
      <transition name="slide">
        <div
          v-if="isDropdownOpen"
          class="splitDropDownContent absolute bg-primary-white py-3 w-full border-t-0 rounded-b-lg border shadow-lg border-border-button z-10"
        >
          <ul v-if="!isInput">
            <li
              v-for="(option, index) in options.subOptions"
              :key="index"
              :class="[...(option.classList || ['text-label-text'])]"
              class="font-roboto py-1 font-medium text-sm sm:text-base ltr:text-left rtl:text-right"
              @click="emitUpdateVal(option)"
            >
              <span class="px-6 md:px-4 w-full block cursor-pointer">
                {{ getLabel({ option: option, label: label }) }}
              </span>
            </li>
          </ul>
          <div v-else>
            <div class="flex justify-between gap-2 px-2">
              <InputBox
                v-model="inputValue"
                :title="inputTitle"
                :placeholder="inputPlaceholder"
                :label="inputTitle"
                class="flex-1"
                :rules="rules"
              />
              <div class="flex gap-2 items-center">
                <button
                  type="button"
                  class="focus:outline-none focus:shadow-md text-xs p-1 text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
                  @click="showDropDown"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="focus:outline-none focus:shadow-md text-xs p-1 text-white font-roboto h-8 flex justify-center items-center primary-button"
                  @click="emitTypedValue"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </UiOnClickOutside>
</template>

<script>
import icon from '@components/icons/icon.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'

export default {
  components: {
    icon,
    UiOnClickOutside,
    InputBox,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },
    isInput: { type: Boolean, default: false },
    reduce: { type: String, default: '' },
    label: { type: String, default: 'title' },
    inputTitle: { type: String, default: 'Enter Here' },
    inputPlaceholder: { type: String, default: 'Placeholder' },
    rules: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isDropdownOpen: false,
      rightBar: 'rightBar',
      inputValue: '',
    }
  },
  computed: {
    getLabel() {
      return ({ option, label }) => {
        if (typeof option === 'object') {
          return option[label]
        }
        return option
      }
    },
  },
  methods: {
    showDropDown() {
      this.isDropdownOpen = !this.isDropdownOpen
      this.inputValue = ''
    },

    closeDropdown() {
      this.isDropdownOpen = false
    },

    emitUpdateVal(item) {
      let value = null

      if (this.reduce) value = item[this.reduce]
      else value = item

      this.$emit('update:modelValue', value)

      this.isDropdownOpen = false
    },

    emitTypedValue() {
      this.$emit('update:modelValue', this.inputValue)
      this.isDropdownOpen = false
      this.inputValue = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-button-outline {
  min-width: 40px;
}
.primary-button {
  min-width: 47px;
}
</style>

<template>
  <TitleWrapper
    :filter-option="true"
    title="MONTLY_FEE_GENERATOR"
    tooltip-title="MONTLY_FEE_GENERATOR_TOOLTIP"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model:initial="filtersPayload.fee_amount_greater_than"
          v-model:secondary="filtersPayload.fee_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="Fee Amount"
          type="range"
          placeholder="Fee Amount"
          rules="numeric"
          :filter="FILTERS_LIST.FEE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.generated_day_greater_than"
          v-model:secondary="filtersPayload.generated_day_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="GENERATED_DAY"
          type="range"
          placeholder="GENERATED_DAY"
          rules="numeric"
          :filter="FILTERS_LIST.GENERATED_DAY_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.due_date_greater_than"
          v-model:secondary="filtersPayload.due_date_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="DUE_DATE"
          type="range"
          placeholder="DUE_DATE"
          rules="numeric"
          :filter="FILTERS_LIST.DUE_DATE_MONTH_RANGE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.fee_type_id"
          title="FEE_TYPE"
          :options="feeTypes"
          :class="$style.widthItems"
          class="flex-1"
          input-color="bg-white"
          reduce="id"
          :label="'title'"
          :filter="FILTERS_LIST.FEE_TYPE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-if="!currentClassScope"
          v-model.trim="filtersPayload.class_id"
          title="CLASS"
          label="title"
          class="flex-1"
          reduce="id"
          :options="classesList"
          :filter="FILTERS_LIST.CLASS"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-if="!currentSectionScope && currentClassScope"
          v-model.trim="filtersPayload.section_id"
          title="SECTS"
          label="title"
          class="flex-1"
          reduce="id"
          :options="sectionsList"
          :filter="FILTERS_LIST.SECTION"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState } from 'vuex'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      sectionsList: [],
      classesList: [],
      FILTERS_LIST,
      feeTypes: [],
      filtersCount: 0,
      filtersPayload: {
        fee_amount_greater_than: '',
        fee_amount_less_than: '',
        generated_day_less_than: '',
        generated_day_greater_than: '',
        due_date_greater_than: '',
        due_date_less_than: '',
        fee_type_id: '',
        class_id: '',
        section_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentClassScope: (state) => state.layout.currentClassScope,
    }),
  },

  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
        this.getCampusClasses()
      },
    },
    currentClassScope: {
      handler() {
        this.clearFilters()
        this.getCampusClasses()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getCampusClasses()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getCampusClasses()
    this.getFeeTypesData()
  },
  methods: {
    getCampusClasses() {
      let payload = {
        campus_id: this.currentCampusScope.id,
      }
      this.getClassesList(payload).then((res) => {
        this.classesList = res.records
      })
      if (this.currentClassScope) {
        this.setSection(this.currentClassScope.id)
      }
    },
    setSection(id) {
      this.classesList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsList = classItem.sections
      })
    },
    getFeeTypesData() {
      this.getFeeTypes().then((res) => {
        this.feeTypes = res.data.records
      })
    },
    getFiltersCount() {
      this.getCampusClasses()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('classes', ['getClassesList']),
    ...mapActions('fee', ['getFeeTypes']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
